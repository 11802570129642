const Loading = () => {
    return (
        <div data-testid="LoadingComponent" className="loading-component">
            <div className="loading-container">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loading;