import './Icon.css';

interface IconProps {
    icon_id: string;
    icon_alt: string;
}

const Icon = (props: IconProps) => {
    return (
        <div data-testid="Icon" className="icon">
            <img
                className="icon-image"
                height="100px"
                width="100px"
                src={`https://ucarecdn.com/${props.icon_id}/-/scale_crop/100x100/center/-/quality/smart/-/format/auto/`}
                alt={props.icon_alt} />
        </div>
    )
}

export default Icon;