import { Link } from "react-router-dom";

import ErrorBoundary from "../../components/common/errorboundary";

const NotFound = () => {
    return (
        <ErrorBoundary>
            <div data-testid="NotFound" className="notfound" style={{ textAlign: 'center' }}>
                <p>
                    Sorry, it looks like you may have taken a wrong turn.
                </p>
                <p>
                    <Link to="/">Click here</Link> to go back to the home page.
                </p>
            </div>
        </ErrorBoundary>
    )
}

export default NotFound;