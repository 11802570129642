import { StringUtils } from "../../../utils/StringUtils";
import { Link } from "react-router-dom";

import ErrorBoundary from "../../common/errorboundary";
import Heading from "../../common/text/heading";
import Grid from "../../common/grid";
import DisplayItem from "../../common/displayitem";

import experienceData from '../../../config/data/experience.json';
import educationData from '../../../config/data/education.json';
import volunteeringData from '../../../config/data/volunteering.json';

const Background = () => {
    return (
        <ErrorBoundary>
            <section data-testid="Background" className="background">
                <Heading variant="h2" className="background-title">
                    Background
                </Heading>

                <Grid columns={2}>
                    <Grid columns={1}>
                        <Heading variant="h3">
                            Experience
                        </Heading>

                        {experienceData.map((experience, index) => {
                            return (
                                <DisplayItem
                                    key={index}
                                    type="background"
                                    title={experience.title}
                                    subtitle={StringUtils.createDateSubtitle(experience.organisation, experience.start_date, experience.end_date)}
                                    icon_id={experience.images?.icon_id}
                                    link={experience.shortcode ? `/experience/${experience.shortcode}` : undefined} />
                            )
                        })}
                        <Link to="/experience" className="link-exclude-accent">View all experience</Link>
                    </Grid>
                    <Grid columns={1}>
                        <Heading variant="h3">
                            Education
                        </Heading>

                        {educationData.slice(0, 4).map((education, index) => {
                            return (
                                <DisplayItem
                                    key={index}
                                    type="background"
                                    title={education.title}
                                    subtitle={StringUtils.createDateSubtitle(education.organisation, education.start_date, education.end_date)}
                                    icon_id={education.images?.icon_id}
                                    link={education.shortcode ? `/education/${education.shortcode}` : undefined} />
                            )
                        })}
                    </Grid>

                    <Grid columns={1}>
                        <Heading variant="h3">
                            Volunteering
                        </Heading>

                        {volunteeringData.map((opportunity, index) => {
                            return (
                                <DisplayItem
                                    key={index}
                                    type="background"
                                    title={opportunity.title}
                                    subtitle={StringUtils.createDateSubtitle(opportunity.organisation, opportunity.start_date, opportunity.end_date)}
                                    icon_id={opportunity.images?.icon_id}
                                    link={opportunity.shortcode ? `/volunteering/${opportunity.shortcode}` : undefined} />
                            )
                        })}
                    </Grid>
                </Grid>
            </section>
        </ErrorBoundary>
    )
}

export default Background;