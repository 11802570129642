import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VolunteeringProps } from "../../interfaces/VolunteeringProps";
import { SectionType } from "../../enums/SectionType";

import ErrorBoundary from "../../components/common/errorboundary";
import Icon from "../../components/sections/details/icon";
import Loading from "../../components/common/states/loading";
import Heading from "../../components/common/text/heading";
import Section from "../../components/sections/details/section";
import Links from "../../components/sections/details/links";
import CustomHelmet from "../../components/common/customhelmet/CustomHelmet";

import volunteeringData from '../../config/data/volunteering.json';

const Volunteering = () => {
    const [loading, setLoading] = useState(true);
    const [opportunity, setOpportunity] = useState<VolunteeringProps | null>(null);

    const location = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(() => {
        const shortcode = location.split('/')[2];

        volunteeringData.forEach((opp) => {
            if (opp.shortcode === shortcode) {
                setOpportunity(opp);
            }
        });

        setLoading(false);
    }, [location]);

    const generateSubtitle = () => {
        let subtitle = opportunity?.organisation;

        if (opportunity?.start_date) {
            subtitle += ` / ${opportunity?.start_date}`;
        }

        if (opportunity?.end_date) {
            subtitle += ` • ${opportunity?.end_date}`;
        } else {
            subtitle += ' • Present';
        }

        return subtitle;
    }

    if (loading) return <Loading />

    if (!loading && !opportunity) {
        navigate('/404');
        return null;
    }

    return (
        <ErrorBoundary>
            <div data-testid="Volunteering" className="volunteering">
                <CustomHelmet title={opportunity?.title} noIndex />

                {opportunity?.images?.icon_id &&
                    <Icon icon_id={opportunity?.images?.icon_id} icon_alt={opportunity?.title} />
                }

                <Heading variant="h1" className="volunteering-title" centered>{opportunity?.title}</Heading>
                <Heading variant="h4" className="volunteering-subtitle" centered>{generateSubtitle()}</Heading>

                {opportunity?.sections &&
                    opportunity?.sections?.map((section, index) => {
                        return <Section title={section.title} type={SectionType[section.type.toUpperCase()]} details={section.details} key={index} />
                    })
                }
            </div>
        </ErrorBoundary>
    )
}

export default Volunteering;