import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ScrollToTop from './components/common/utils/scrolltotop/ScrollToTop';

import Home from './pages/home';
import Layout from './pages/layout';
import AllProjects from './pages/all-projects';
import Project from './pages/project';
import AllExperience from './pages/all-experience';
import Experience from './pages/experience';
import NotFound from './pages/notfound';
import Education from './pages/education';
import Volunteering from './pages/volunteering';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<AllProjects />} />
            <Route path="/project/:shortcode" element={<Project />} />
            <Route path="/projects/:shortcode" element={<Project />} />
            <Route path="/experience" element={<AllExperience />} />
            <Route path="/experience/:shortcode" element={<Experience />} />
            <Route path="/education/:shortcode" element={<Education />} />
            <Route path="/volunteering/:shortcode" element={<Volunteering />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
