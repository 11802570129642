import ErrorBoundary from "../../components/common/errorboundary"
import CustomHelmet from "../../components/common/customhelmet";
import Projects from "../../components/sections/projects";

import activeProjects from '../../config/data/projects.json';
import historicProjects from '../../config/data/projects_all.json';

const AllProjects = () => {
    return (
        <ErrorBoundary>
            <div data-testid="AllProjects" className="all-projects">
                <CustomHelmet title="Projects" />

                <Projects title="Featured Projects" projects={activeProjects} />
                <Projects title="All Projects" projects={historicProjects} hide_icon />
            </div>
        </ErrorBoundary>
    )
}

export default AllProjects;