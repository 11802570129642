import ErrorBoundary from "../../common/errorboundary";
import DisplayItem from "../../common/displayitem";
import Heading from "../../common/text/heading";
import Grid from "../../common/grid";

interface ProjectsProps {
    title?: string;
    projects: any[];
    hide_icon?: boolean;
}

const Projects = (props: ProjectsProps) => {
    return (
        <ErrorBoundary>
            <section data-testid="Projects" className="projects">
                <Heading variant="h2" className="projects-title">
                    {props.title || 'Projects'}
                </Heading>

                <Grid columns={2}>
                    {props.projects.map((project, index) => {
                        return (
                            <DisplayItem
                                key={index}
                                type="project"
                                title={project.title}
                                subtitle={project.tagline}
                                icon_id={project.images?.icon_id}
                                hide_icon={props.hide_icon}
                                link={project.shortcode ? `/projects/${project.shortcode}` : undefined} />
                        )
                    })}
                </Grid>
            </section>
        </ErrorBoundary>
    )
}

export default Projects;