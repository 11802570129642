import { Suspense } from "react";
import { Outlet } from "react-router";

import Header from "../../components/common/header";

const Layout = () => {
    return (
        <div data-testid="Layout" className="layout">
            <Header />

            <Suspense fallback={<div>Loading...</div>}>
                <div className="main-content">
                    <Outlet />
                </div>
            </Suspense>
        </div>
    )
}

export default Layout;