import { Component } from "react";

import ErrorState from "../states/error";

interface IErrorBoundary {
    children: React.ReactNode;
    hideError?: boolean;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
    constructor(props: IErrorBoundary) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log("Something went wrong");
    }

    render() {
        if (this.state.hasError) {
            return <ErrorState hideError={this.props.hideError} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;