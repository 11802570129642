import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExperienceProps } from "../../interfaces/ExperienceProps";
import { SectionType } from "../../enums/SectionType";
import { StringUtils } from "../../utils/StringUtils";

import ErrorBoundary from "../../components/common/errorboundary";
import Icon from "../../components/sections/details/icon";
import Loading from "../../components/common/states/loading";
import Heading from "../../components/common/text/heading";
import Section from "../../components/sections/details/section";
import Links from "../../components/sections/details/links";
import CustomHelmet from "../../components/common/customhelmet/CustomHelmet";

import experienceData from '../../config/data/experience.json';

const Experience = () => {
    const [loading, setLoading] = useState(true);
    const [experience, setExperience] = useState<ExperienceProps | null>(null);

    const location = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(() => {
        const shortcode = location.split('/')[2];

        experienceData.forEach((exp) => {
            if (exp.shortcode === shortcode) {
                setExperience(exp);
            }
        });

        setLoading(false);
    }, [location]);

    if (loading) return <Loading />

    if (!loading && !experience) {
        navigate('/404');
        return null;
    }

    return (
        <ErrorBoundary>
            <div data-testid="Experience" className="experience">
                <CustomHelmet title={experience?.title} noIndex />

                {experience?.images?.icon_id &&
                    <Icon icon_id={experience?.images?.icon_id} icon_alt={experience?.title} />
                }

                <Heading variant="h1" className="experience-title" centered>{experience?.title}</Heading>
                <Heading variant="h4" className="experience-subtitle" centered>{StringUtils.createDateSubtitle(experience?.organisation, experience?.start_date, experience?.end_date)}</Heading>

                {experience?.sections &&
                    experience?.sections?.map((section, index) => {
                        return <Section title={section.title} type={SectionType[section.type.toUpperCase()]} details={section.details} key={index} />
                    })
                }

                {(experience?.links && experience?.links?.length > 0) &&
                    <Links links={experience?.links} />
                }
            </div>
        </ErrorBoundary>
    )
}

export default Experience;