import ErrorBoundary from "../../common/errorboundary";
import DisplayItem from "../../common/displayitem";
import Heading from "../../common/text/heading";
import Grid from "../../common/grid";
import { StringUtils } from "../../../utils/StringUtils";

interface ExperienceProps {
    title?: string;
    experiences: any[];
    hide_icon?: boolean;
}

const ExperiencesList = (props: ExperienceProps) => {
    return (
        <ErrorBoundary>
            <section data-testid="ExperiencesList" className="experiences-list">
                <Heading variant="h2" className="experiences-list-title">
                    {props.title || 'Experiences'}
                </Heading>

                <Grid columns={2}>
                    {props.experiences.map((experience, index) => {
                        return (
                            <DisplayItem
                                key={index}
                                type="background"
                                title={experience.title}
                                subtitle={StringUtils.createDateSubtitle(experience.organisation, experience.start_date, experience.end_date)}
                                icon_id={experience.images?.icon_id}
                                hide_icon={props.hide_icon}
                                link={experience.shortcode ? `/experience/${experience.shortcode}` : undefined} />
                        )
                    })}
                </Grid>
            </section>
        </ErrorBoundary>
    )
}

export default ExperiencesList;