import { useState } from "react";
import { StringUtils } from "../../../../utils/StringUtils";
import { SectionType } from "../../../../enums/SectionType";

import ErrorBoundary from "../../../common/errorboundary";
import Heading from "../../../common/text/heading";

import './Section.css';

interface SectionProps {
    title: string;
    type: SectionType;
    details: string[];
}

const Section = (props: SectionProps) => {
    const [open, setOpen] = useState((props.type === SectionType.COLLAPSIBLE || props.type === SectionType.COLLAPSIBLE_LIST) ? false : true);

    return (
        <ErrorBoundary>
            <section
                data-testid="Section"
                className={`section ${props.type} ${((props.type === SectionType.COLLAPSIBLE || props.type === SectionType.COLLAPSIBLE_LIST) && open) ? 'open' : ''}`.trim()}
                id={StringUtils.sluggify(props.title)}>

                <Heading
                    variant="h3"
                    className="section-title"
                    onClick={(props.type === SectionType.COLLAPSIBLE || props.type === SectionType.COLLAPSIBLE_LIST) ? () => setOpen((open) => !open) : undefined}>
                    {props.title}
                </Heading>

                <div className="section-content">
                    {(props.type === SectionType.LIST || props.type === SectionType.COLLAPSIBLE_LIST) &&
                        <ul className="section-list">
                            {props.details.map((detail, index) => {
                                return (
                                    <li key={index} className="section-detail">
                                        {detail}
                                    </li>
                                )
                            })}
                        </ul>
                    }

                    {(props.type === SectionType.TEXT || props.type === SectionType.COLLAPSIBLE) &&
                        props.details.map((detail, index) => {
                            return (
                                <p key={index} className="section-detail">
                                    {detail}
                                </p>
                            )
                        })
                    }
                </div>
            </section>
        </ErrorBoundary>
    )
}

export default Section;