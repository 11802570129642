export class DeviceUtils {
    static isMobile() {
        const userAgent = navigator.userAgent.toLowerCase();
        const mobileUserAgents = [
            "android",
            "iphone",
            "ipad",
            "ipod",
            "blackberry",
            "windows phone",
            "opera mini",
            "palm webOS",
            "meego",
            "bada",
        ];

        for (const mobileUserAgent of mobileUserAgents) {
            if (userAgent.indexOf(mobileUserAgent) > -1) {
                return true;
            }
        }

        return false;
    }

    static isTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        const tabletUserAgents = [
            "android tablet",
            "ipad",
            "playbook",
            "kindle fire",
        ];

        for (const tabletUserAgent of tabletUserAgents) {
            if (userAgent.indexOf(tabletUserAgent) > -1) {
                return true;
            }
        }

        return false;
    }

    static isDesktop() {
        return /(Windows|Linux|Mac OS X)/i.test(navigator.userAgent) && window.innerWidth >= 768;
    }

    static hasKeyboardAndMouse() {
        // Check if the user agent string contains any mobile or tablet user agents.
        const userAgent = navigator.userAgent.toLowerCase();
        const mobileUserAgents = [
            "android",
            "iphone",
            "ipad",
            "ipod",
            "blackberry",
            "windows phone",
            "opera mini",
            "palm webOS",
            "meego",
            "bada",
        ];

        for (const mobileUserAgent of mobileUserAgents) {
            if (userAgent.indexOf(mobileUserAgent) > -1) {
                return false;
            }
        }

        // Check if the `pointer` media feature is supported and set to `coarse`.
        const mediaQuery = window.matchMedia("(pointer: coarse)");
        if (mediaQuery.matches) {
            return false;
        }

        // The device has a keyboard and mouse.
        return true;
    }

    /**
     * Check if the user has enabled dark mode in their OS settings.
     * @returns {boolean} True if the user has enabled dark mode in their OS settings.
     */
    static isDarkModeActive(): boolean {
        return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
}