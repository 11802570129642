import { Link } from "react-router-dom";

import ErrorBoundary from "../../../common/errorboundary";
import Heading from "../../../common/text/heading/Heading";

interface LinksProps {
    links: {
        title: string;
        url: string;
    }[];
}

const Links = (props: LinksProps) => {
    return (
        <ErrorBoundary>
            <section data-testid="Links" className="links" id="links">
                <Heading variant="h3" className="links-title">
                    Links
                </Heading>

                {props.links.map((link, index) => {
                    return (
                        <p>
                            <Link key={index} className="links-link" to={link.url} target="_blank">
                                {link.title}
                            </Link>
                        </p>
                    )
                })}
            </section>
        </ErrorBoundary>
    )
}

export default Links;