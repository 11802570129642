import CustomHelmet from "../../components/common/customhelmet";
import Profile from "../../components/sections/profile";
import Projects from "../../components/sections/projects";
import Background from "../../components/sections/background";

import activeProjects from '../../config/data/projects.json';
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div data-testid="Home" className="home">
            <CustomHelmet description="Homepage for Karl Jones || Technical Support Manager || Software Developer" />
            <Profile />
            <Projects projects={activeProjects} />
            <Link to="/projects" className="link-exclude-accent">View all projects</Link>
            <Background />
        </div>
    )
}

export default Home;