import { StringUtils } from '../../../utils/StringUtils';
import { Link } from 'react-router-dom';

import ErrorBoundary from '../errorboundary';

import './DisplayItem.css';

interface DisplayItemProps {
    type: 'project' | 'background';
    title: string;
    subtitle: string;
    icon_id?: string | undefined | null;
    icon_alt?: string;
    hide_icon?: boolean;
    link?: string;
}

const DisplayItem = (props: DisplayItemProps) => {
    const IMAGE_SIZE = 50;

    return (
        <ErrorBoundary>
            <div data-testid="DisplayItem" className={`displayitem ${props.type} ${(!props.hide_icon && props.icon_id) ? 'has-icon' : ''}`} id={StringUtils.sluggify(props.title)}>
                {(!props.hide_icon && props.icon_id) &&
                    <div className="displayitem-icon-container">
                        {props.link
                            ?
                            <Link to={props.link} className="displayitem-icon-link">
                                <img
                                    className="displayitem-icon"
                                    height={`${IMAGE_SIZE}px`}
                                    width={`${IMAGE_SIZE}px`}
                                    src={`https://ucarecdn.com/${props.icon_id}/-/scale_crop/${IMAGE_SIZE}x${IMAGE_SIZE}/center/-/quality/smart/-/format/auto/`}
                                    alt={props.icon_alt ? props.icon_alt : props.title} />
                            </Link>
                            :
                            <img
                                className="displayitem-icon"
                                height={`${IMAGE_SIZE}px`}
                                width={`${IMAGE_SIZE}px`}
                                src={`https://ucarecdn.com/${props.icon_id}/-/scale_crop/${IMAGE_SIZE}x${IMAGE_SIZE}/center/-/quality/smart/-/format/auto/`}
                                alt={props.title} />
                        }
                    </div>
                }

                <div className="displayitem-content">
                    {props.link
                        ?
                        <Link to={props.link}>
                            <h3 className="displayitem-title">
                                {props.title}
                            </h3>
                        </Link>
                        :
                        <h3 className="displayitem-title">
                            {props.title}
                        </h3>
                    }


                    <p className="displayitem-tagline">{props.subtitle}</p>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default DisplayItem;