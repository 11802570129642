import { DeviceUtils } from '../../../utils/DeviceUtils';

import './Grid.css';

interface GridProps {
    columns: 1 | 2 | 3 | 4;
    columnGap?: number;
    rowGap?: number;
    children: React.ReactNode;
}

const Grid = (props: GridProps) => {
    return (
        <div data-testid="Grid" className={`grid col_${props.columns} ${DeviceUtils.isMobile() ? 'mobile' : ''}`.trim()} style={{
            columnGap: props.columnGap ? `${props.columnGap}px` : '8px',
            rowGap: props.rowGap ? `${props.rowGap}px` : '8px',
        }}>
            {props.children}
        </div>
    )
}

export default Grid;