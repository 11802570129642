import ErrorBoundary from "../errorboundary";

import "./ProfileImage.css";

const ProfileImage = () => {
    const IMAGE_SIZE = 200;
    const PROFILE_IMAGE_ID = "0e91d461-f3bc-497e-bc0e-1fa23ce1e016"

    return (
        <ErrorBoundary>
            <img
                className="profile-image"
                width={`${IMAGE_SIZE}px`}
                height={`${IMAGE_SIZE}px`}
                src={`https://ucarecdn.com/${PROFILE_IMAGE_ID}/-/scale_crop/${IMAGE_SIZE}x${IMAGE_SIZE}/center/-/quality/smart/-/format/auto/`}
                alt="Karl Jones"
                loading="eager" />
        </ErrorBoundary>
    )
}

export default ProfileImage;