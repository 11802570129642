import ErrorBoundary from "../../errorboundary";

import './Heading.css';

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    centered?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

const Heading = (props: HeadingProps) => {
    return (
        <ErrorBoundary>
            <props.variant
                className={`heading ${props.className || ''} ${props.centered ? 'centered' : ''}`.trim()}
                onClick={props.onClick}
                style={{ ...props.style, cursor: props.onClick ? 'pointer' : undefined }}>
                {props.children}
            </props.variant>
        </ErrorBoundary>
    )
}

export default Heading;