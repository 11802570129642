import { DeviceUtils } from '../../../utils/DeviceUtils';

import ErrorBoundary from '../../common/errorboundary';
import Heading from '../../common/text/heading';
import ProfileImage from '../../common/profile-image';

import './Profile.css';

const Profile = () => {
    const IMAGE_SIZE = 200;
    const MOBILE_IMAGE_SIZE = 100;
    const PROFILE_IMAGE_ID = "0e91d461-f3bc-497e-bc0e-1fa23ce1e016"
    // 338c7fda-abe2-4e0f-897f-408827e5ef90

    return (
        <ErrorBoundary>
            <section data-testid="Profile" className={`profile ${DeviceUtils.isMobile() ? 'mobile' : ''}`.trim()}>
                <div className="profile-content-container">
                    {!DeviceUtils.isMobile() &&
                        <div className="profile-picture col_25">
                            <ProfileImage />
                        </div>
                    }
                    <div className={`profile-content ${DeviceUtils.isDesktop() ? 'col_75' : ''}`.trim()}>
                        <p>Hello, my name is</p>
                        <Heading variant="h1" className="profile-content-title">
                            Karl Jones
                        </Heading>
                        <p>I'm a web developer in Glasgow, Scotland and I'm passionate about bridging the gap between customer and software.</p>
                    </div>
                </div>
            </section>
        </ErrorBoundary>
    )
}

export default Profile;