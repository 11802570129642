import ErrorBoundary from "../../components/common/errorboundary";
import CustomHelmet from "../../components/common/customhelmet";
import ExperiencesList from "../../components/sections/experiences-list";

import allExperience from '../../config/data/experience_all.json';

const AllExperience = () => {
    return (
        <ErrorBoundary>
            <div data-testid="AllExperience" className="all-experience">
                <CustomHelmet title="Experience" />

                <ExperiencesList title="Experience" experiences={allExperience} />
            </div>
        </ErrorBoundary>
    )
}

export default AllExperience;