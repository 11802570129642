import { Link, useLocation } from 'react-router-dom';

import ErrorBoundary from '../errorboundary/ErrorBoundary';

import './Header.css';

const Header = () => {
    const location = useLocation().pathname;
    return (
        <ErrorBoundary>
            <nav data-testid="Header" className="header">
                <div className="header-navigation">
                    {location !== "/" && <Link to="/" className="header-navigation-link">Home</Link>}
                    <Link to="https://www.linkedin.com/in/kjoones/" className="header-navigation-link" target="_blank" title="LinkedIn">
                        LinkedIn
                    </Link>
                    <Link to="https://www.github.com/KarlJoones" className="header-navigation-link" target="_blank" title="Github">
                        Github
                    </Link>
                    {/* <Link to="https://www.buymeacoffee.com/KarlJones" className="header-navigation-link" target="_blank" title="Buy me a Coffee">
                        Buy me a Coffee
                    </Link> */}
                </div>
            </nav>
        </ErrorBoundary >
    )
}

export default Header;