export class StringUtils {
    static isNullOrEmpty(value: string | undefined | null): boolean {
        return value === undefined || value === null || value === "";
    }

    /**
     * Sluggify a string to remove the special characters and replace the spaces with dashes, this is used for urls and ids
     * @example "Technical University Dublin" will be converted to "technical-university-dublin"
     * @param value string to be converted to slug
     * @returns a string that has no special characters and spaces are replaced with dashes
     */
    static sluggify(value: string): string {
        return value.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-");
    }

    /**
     * Abbreviate a given string. This will only include words that start with a capital letter
     * @example "Technical University Dublin" will be abbreviated to "TUD"
     * @example "Technical university Dublin" will be abbreviated to "TD"
     * @param value that the user would like to abbreviate
     * @returns 
     */
    static abbreviate(value: string): string {
        const words = value.split(" ");
        var abbr = "";

        for (var i = 0; i < words.length; i++) {
            if (words[i][0] === words[i][0].toUpperCase()) {
                abbr += words[i][0].toUpperCase();
            }
        }
        return abbr;
    }

    static createDateSubtitle(organisation: string | undefined, startDate: string | undefined, endDate: string | undefined): string {
        const DELIMITER = "/";
        const CHARACTER_LIMIT = 25;

        if (this.isNullOrEmpty(organisation)) {
            return "";
        }

        let sub = "";
        sub += (organisation && organisation.length > CHARACTER_LIMIT) ? StringUtils.abbreviate(organisation) : organisation;

        if (startDate) {
            sub += ` ${DELIMITER} ${startDate}`;
        }

        if (endDate) {
            sub += ` • ${endDate}`;
        } else {
            sub += ` • Present`;
        }

        return sub;
    }
}